import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  methods: {
    generateLiffUrl(path) {
      return `https://liff.line.me/${this.organization.liff_id}/${path}`;
    },
  },
}
